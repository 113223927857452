<template>
  <div>
    <el-form size="small" class="query-form"
             @keyup.enter.native="getDataList(1,1)"
             ref="inputForm" :inline="true" :model="inputForm" label-width="80px">
      <el-form-item label="消毒日期" prop="disinfectTime">
        <el-date-picker
            v-model="inputForm.disinfectTime"
            type="daterange"
            clearable
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">>
        </el-date-picker>
      </el-form-item>
      <el-form-item label="消毒人员" prop="disinfectUser">
        <el-input
            v-model="inputForm.disinfectUser"
            maxlength="50"
            clearable
            placeholder="消毒人员(限50字)">
        </el-input>
      </el-form-item>
      <el-form-item label="消毒方式" prop="disinfectWay">
        <el-select v-model="inputForm.disinfectWay" clearable placeholder="请选择消毒方式" style="width: 100%;">
          <el-option
              v-for="item in this.$dictUtils.getDictList('disinfection_method')"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <div class="flex_b_c">
        <div>
          <el-button size="small" type="primary" @click="getDataList(1,1)" icon="el-icon-search">查询</el-button>
          <el-button size="small" @click="resetting()" icon='el-icon-refresh-right'>重置</el-button>
        </div>
        <div>
          <el-button size="small" @click="bulkExport(0)">
            <i class="icon-piliangdaochu iconfont buIcon"/>
            批量导出
          </el-button>
        </div>
      </div>
    </el-form>
    <el-table
        :data="dataList"
        size="small"
        v-loading="loading"
        height="calc(100vh - 430px)"
        ref="multipleTable"
        class="table"
        :row-key="'id'"
        @selection-change="selectionChangeHandle"
    >
      <el-table-column :reserve-selection="true" type="selection" width="50"/>
      <el-table-column label="序号" type="index" width="50"></el-table-column>
      <el-table-column prop="disinfectTime" sortable label="消毒时间" width="150px" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.disinfectTime | formatDate('YYYY-MM-DD') }}
        </template>
      </el-table-column>
      <el-table-column prop="disinfectUser" label="消毒人员" show-overflow-tooltip></el-table-column>
      <el-table-column prop="disinfectWay" label="消毒方式" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ $dictUtils.getDictLabel("disinfection_method", scope.row.disinfectWay, '-') }}
        </template>
      </el-table-column>
      <el-table-column prop="createUserName" label="创建者" show-overflow-tooltip></el-table-column>
      <el-table-column prop="createDate" sortable label="创建时间" width="150px">
        <template slot-scope="scope">
          {{ scope.row.createDate | formatDate }}
        </template>
      </el-table-column>
      <el-table-column prop="updateUserName" label="最后更新人"
                       show-overflow-tooltip></el-table-column>
      <el-table-column prop="updateDate" sortable label="最后更新时间" width="150px"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.updateDate | formatDate }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="180">
        <template slot-scope="scope">
          <el-button style="margin-bottom: 10px" size="mini" type="text"
                     @click="collDetail(scope.row)">
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="text_center">
      <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="current"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="size"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {Base64} from 'js-base64'

export default {
  name: "disinfects",
  props: {
    //藏品id
    id: {
      type: String,
      default: '',
    },
    selectTab: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      inputForm: {
        disinfectTime: [],
        disinfectStartTime: '',
        disinfectEndTime: '',
        disinfectUser: '',
        disinfectWay: '',
      },
      dataList: [],
      dataListSelect: [],
      loading: false,
      current: 1,
      size: 10,
      total: 0,
    }
  },

  mounted() {
    this.getDataList()
  },

  methods: {
    getDataList(type, dividePage) {
      if (type == 1) {
        this.current = 1
      }
      if (dividePage) {
        this.$refs.multipleTable.clearSelection()
      }
      if (this.inputForm.disinfectTime && this.inputForm.disinfectTime.length != 0) {
        this.inputForm.disinfectStartTime = this.inputForm.disinfectTime[0]
        this.inputForm.disinfectEndTime = this.inputForm.disinfectTime[1]
      } else {
        this.inputForm.disinfectStartTime = ''
        this.inputForm.disinfectEndTime = ''
      }
      this.loading = true
      this.$axios(this.api.collection.collectiondisinfectList, {
        ...this.inputForm,
        collectionName: '',
        current: this.current,
        collectionId: this.id,
        size: this.size,
      }, 'get').then((res) => {
        if (res.status) {
          this.dataList = res.data.records
          this.total = parseInt(res.data.total)
          if (this.dataList.length == 0 && this.current != 1) {
            this.current--;
            this.getDataList()
          }
        } else {
          this.$message.error('查询失败');
        }
        this.loading = false
      })
    },

    resetting() {
      this.$refs.inputForm.resetFields()
      this.getDataList(1, 1)
    },

    //表格勾选数据
    selectionChangeHandle(val) {
      this.dataListSelect = val
    },

    //批量导出
    bulkExport() {
      let ids = []
      if (this.dataListSelect.length == 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      ids = this.dataListSelect.map(item => {
        if (item) {
          return item.id
        }
      })

      let data = {
        ids: ids,
      }
      this.exportExcel(this.api.collection.collectiondisinfectExport, data, '消毒资料', 'post')
      this.getDataList('', 1)
    },

    //预览
    previewPdf(url) {
      this.pdfUrl = url
      window.open(this.$pdfPreviewUrl() + 'onlinePreview?url=' + encodeURIComponent(Base64.encode(this.pdfUrl)));
    },

    //详情
    collDetail(row) {
      let listSearchCollDetail = {
        selectTab: this.selectTab,
        inputForm: this.inputForm,
        current: this.current,
        size: this.size,
      }
      sessionStorage.setItem('listSearchCollDetail', JSON.stringify(listSearchCollDetail))
      let routerUrl = {
        path: '/collection/business/disinfectDetail',
        query: {butType: 5, id: row.id},
      }
      this.$emit('gotoOtherDetail', routerUrl)
    },

    // 展览每页数
    sizeChangeHandle(val) {
      this.size = val;
      this.getDataList(1);
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList();
    },
  },
}
</script>

<style scoped>
.switchList {
  font-size: 40px;

  img {
    width: 40px;
    cursor: pointer;
  }
}

.box-card {
  margin-bottom: 10px;

  &::v-deep .el-card__body {
    padding: 10px !important;
  }
}

.noAccessory {
  text-align: center;

  img {
    width: 20%;
  }
}
</style>
